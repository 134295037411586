import { gql } from "@apollo/client";

// Define your GraphQL query
// export const GET_COMMANDS = gql`
//  query Commands($filters: CommandFiltersInput, $pagination: PaginationArg) {
//   commands(filters: $filters, pagination: $pagination) {
//     company_id {
//       name
//     }
//     dropOfAddress {
//       Address
//     }
//     pickUpAddress {
//       Address,coordonne {
//         latitude,longitude
//       }
//     }
//   }
// }`;

export const GET_RESERVATION_BY_ID = gql`
  query GetReservationById($id: ID!) {
    reservation(id: $id) {
      id
      name
      status
    }
  }
`;

export const GET_RESERVATIONS = gql`
  query Commands_connection(
    $filters: CommandFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    commands_connection(filters: $filters, pagination: $pagination, sort: $sort) {
      nodes {
        createdAt
        updatedAt
        documentId
        refNumber
        payType
        company_id {
          name
          documentId
        }
        dropOfAddress {
          Address
        }
        pickUpAddress {
          Address
          coordonne {
            latitude
            longitude
          }
        }
        duration
        distance

        totalPrice
        commandStatus
        departDate
        deparTime
        driver_id {
          documentId
          firstName
          lastName
        }
        client_id {
          documentId
          username
          firstName
          lastName
          email
          phoneNumber
          profilePicture {
            url
          }
        }
        isAccepted
        publishedAt
      }
      pageInfo {
        total
        page
        pageSize
        pageCount
      }
    }
  }
`;

export const GET_RESERVATIONS_COUNT = gql`
  query Commands_connection($filters: CommandFiltersInput) {
    commands_connection(filters: $filters) {
      pageInfo {
        total
      }
    }
  }
`;
